<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('marketplace.profile-visibility')"
    @open="initForm">
    <el-form ref="visibilityForm"
      :model="visibilityForm"
      label-position="top">
      <!-- Disclaimer text if profile is private -->
      <p v-if="!marketplaceUserProfile.active">
        {{ $t('marketplace.currently-private') }}
      </p>

      <!-- Profile Visibility -->
      <el-form-item prop="active"
        :label="$t('marketplace.profile-visibility')">
        <el-select v-model="visibilityForm.active">
          <el-option v-for="option in visibilityOptions"
            :key="option.label"
            :label="option.label"
            :value="option.value" />
        </el-select>
      </el-form-item>
    </el-form>

    <!-- Warning text if no services are public -->
    <p v-if="!hasPublicServices">
      {{ $t('marketplace.private-services-warning') }}
    </p>

    <template #footer>
      <el-button type="primary"
        :disabled="!hasPublicServices && !marketplaceUserProfile.active"
        @click="submitForm">
        {{ $t('general.save') }}
      </el-button>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditVisibilityModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      visibilityForm: {
        active: null,
      },
      visibilityOptions: [
        {
          label: this.$t('general.public'),
          value: true,
        },
        {
          label: this.$t('general.private'),
          value: false,
        },
      ],
    }
  },

  computed: {
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    /**
     * Check if the user has any public services
     * @returns {boolean} True if the user has any public services
     */
    hasPublicServices() {
      if (!this.marketplaceUserProfile.services.length) return false
      return this.marketplaceUserProfile.services.some(service => service.marketplaceVisible)
    },
  },

  methods: {
    ...mapActions('Marketplace', ['updateMarketplaceProfile']),
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Initialise the visibility field value to the current marketplace profile visibility
     */
    initForm() {
      this.visibilityForm.active = this.marketplaceUserProfile.active
    },
    /**
     * Updates the marketplace profile visibility via the API
     */
    async submitForm() {
      await this.updateMarketplaceProfile({
        ...this.marketplaceUserProfile,
        active: this.visibilityForm.active,
      })
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 450px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-select {
    width: 100%;
  }
}
</style>
