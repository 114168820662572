<template>
  <section>
    <!-- No Services Message -->
    <div v-if="!servicesArray.length"
      class="empty-text">
      <i class="fas fa-empty-set"
        aria-hidden="true" />
      <p>{{ $t('services.no-matching') }}</p>
    </div>

    <template v-else>
      <div class="profile-header">
        <h4 class="color-regular">
          {{ $t('general.services') }}
        </h4>
      </div>

      <!-- Filter and Sort -->
      <el-form ref="servicesForm"
        :model="servicesForm"
        label-position="top"
        class="d-flex justify-content-space-between flex-align-end mb-1-5">
        <div class="services-filter-sort">
          <el-form-item :label="$t('general.filter')"
            prop="filter"
            class="mb-0">
            <el-select id="filter"
              v-model="servicesForm.filter"
              data-testid="filter">
              <el-option v-for="filter in filterOptions"
                :key="filter.value"
                :label="filter.label"
                :value="filter.value"
                :data-testid="`option-${filter.label.toLowerCase()}`" />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('general.sort')"
            prop="sort"
            class="mb-0">
            <el-select id="sort"
              v-model="servicesForm.sort"
              class="sort-select">
              <el-option v-for="sort in sortOptions"
                :key="sort.value"
                :label="sort.label"
                :value="sort.value" />
            </el-select>
          </el-form-item>
        </div>

        <el-button class="expand-collapse-button"
          data-testid="expand-collapse-button"
          @click="expandCollapseAll">
          <i class="far fa-arrows-to-line"
            aria-hidden="true" />
          {{ $t(`services.${allExpanded ? 'collapse' : 'expand'}-all`) }}
        </el-button>
      </el-form>

      <!-- List of Services -->
      <TransitionGroup name="services">
        <el-card v-for="service in filteredServices"
          :key="service.productUid"
          shadow="never"
          :class="{ 'collapsed': !service.expanded }"
          class="mb-2">
          <!-- Card Header -->
          <template #header>
            <div class="d-flex gap-0-5 flex-align-center">
              <!-- Marketplace services are treated as VXCs -->
              <mu-mega-icon icon="vxc"
                height="30"
                class="w-auto" />

              <!-- Service Title -->
              <h5 class="service-title">
                {{ service.title }}
              </h5>

              <!-- Service Visibility -->
              <el-tag :type="tagVariant(service.marketplaceVisible)"
                size="small"
                class="visibility-tag"
                :data-testid="`service-tag-${service.marketplaceVisible ? 'public' : 'private'}`">
                <i :class="service.marketplaceVisible ? 'fa-eye' : 'fa-eye-slash'"
                  class="fas"
                  aria-hidden="true" />
                {{ service.marketplaceVisible ? $t('general.public') : $t('general.private') }}
              </el-tag>
            </div>

            <div class="min-w-140px">
              <!-- Edit Service Button -->
              <el-button v-if="canEdit"
                @click="openEditModal(service.productUid)">
                <i class="far fa-cog"
                  aria-hidden="true" />
                {{ $t('general.edit') }}
              </el-button>

              <!-- Expand Arrow -->
              <el-button class="expand-button"
                data-testid="expand-service"
                @click="expandService(service.productUid)">
                <i class="far fa-chevron-up"
                  :class="{ 'rotated': !service.expanded }"
                  aria-hidden="true" />
              </el-button>
            </div>
          </template>

          <!-- Expanded Service Details -->
          <el-collapse-transition>
            <div v-if="service.expanded"
              class="pt-2">
              <div class="px-2 pb-2">
                <!-- Title -->
                <simple-heading-text-block :label="$t('marketplace.display-name')"
                  :value="service.title" />

                <!-- Description -->
                <simple-heading-text-block v-if="service.description"
                  :label="$t('general.description')"
                  :value="service.description" />

                <!-- Contact Email -->
                <simple-heading-text-block v-if="service.contactEmail"
                  :label="$t('marketplace.contact-email')">
                  <a :href="`mailto:${service.contactEmail}`"
                    rel="noopener">
                    {{ service.contactEmail }}
                  </a>
                </simple-heading-text-block>

                <!-- Service Types -->
                <simple-heading-text-block v-if="service.serviceTypes.length"
                  :label="$t('marketplace.service-types')"
                  :value="serviceTypes(service.serviceTypes)" />
              </div>

              <!-- Stupid element-ui doesn't have support for native card footers -->
              <div class="card-footer pt-2 px-2">
                Created: {{ formatDate(service.createDate) }}
              </div>
            </div>
          </el-collapse-transition>
        </el-card>
      </TransitionGroup>

      <!-- No Filtered Services Message -->
      <div v-if="!filteredServices.length"
        class="empty-text">
        <i class="fas fa-empty-set"
          aria-hidden="true" />
        <p>{{ $t('marketplace.no-services-filter') }}</p>
      </div>

      <!-- Edit Service Modal -->
      <edit-service-modal :visible.sync="showEditModal"
        :service="editService"
        @submit="saveServices" />
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'

import SimpleHeadingTextBlock from '@/components/ui-components/SimpleHeadingTextBlock.vue'
import EditServiceModal from '@/components/marketplace/profile/modals/EditServiceModal.vue'

const FILTER_ALL = 'all'
const FILTER_PUBLIC = 'public'
const FILTER_PRIVATE = 'private'

export default {
  name: 'ProfileServicesTab',

  components: {
    'simple-heading-text-block': SimpleHeadingTextBlock,
    'edit-service-modal': EditServiceModal,
  },

  data() {
    return {
      // Array of services to display including additional information
      servicesArray: [],
      showEditModal: false,
      // Selected service to edit in the modal
      editService: {},
      filterOptions: [
        {
          label: this.$t('general.all'),
          value: FILTER_ALL,
        },
        {
          label: this.$t('general.public'),
          value: FILTER_PUBLIC,
        },
        {
          label: this.$t('general.private'),
          value: FILTER_PRIVATE,
        },
      ],
      sortOptions: [
        {
          label: this.$t('general.creation-date-ascending'),
          value: 'createDate-ascending',
        },
        {
          label: this.$t('general.creation-date-descending'),
          value: 'createDate-descending',
        },
        {
          label: this.$t('general.name-ascending'),
          value: 'title-ascending',
        },
        {
          label: this.$t('general.name-descending'),
          value: 'title-descending',
        },
        {
          label: this.$t('general.location-ascending'),
          value: 'locationName-ascending',
        },
        {
          label: this.$t('general.location-descending'),
          value: 'locationName-descending',
        },
      ],
      servicesForm: {
        filter: 'all',
        sort: 'title-ascending',
      },
    }
  },

  computed: {
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Services', ['findPort', 'portUidDictionary']),
    ...mapGetters('Auth', ['hasAuth']),
    /**
     * Restrict editing to company admins only.
     */
    canEdit() {
      return this.hasAuth('company_admin')
    },
    /**
     * Check if all services are expanded.
     */
    allExpanded() {
      return this.servicesArray.every(service => service.expanded)
    },
    /**
     * Filter services based on the selected filter and sort options.
     */
    filteredServices() {
      // Extract the sort key and direction from the selected sort option
      const sortKey = this.servicesForm.sort.replace(/-ascending|-descending/g, '').trim()
      const isDescending = this.servicesForm.sort.includes('desc')

      return this.servicesArray
        .filter(service => {
          if (this.servicesForm.filter === FILTER_PUBLIC) {
            return service.marketplaceVisible
          } else if (this.servicesForm.filter === FILTER_PRIVATE) {
            return !service.marketplaceVisible
          }
          return true // No filter applied
        })
        .sort((a, b) => {
          // Date sorting is a special case as it's a number
          if (sortKey === 'createDate') {
            if (a[sortKey] > b[sortKey]) return isDescending ? -1 : 1
            if (a[sortKey] < b[sortKey]) return isDescending ? 1 : -1
            return 0
          }

          const comparison = a[sortKey].localeCompare(b[sortKey])
          return isDescending ? -comparison : comparison
        })
    },
  },

  watch: {
    // Update the services array we're working with whenever the user's profile changes
    marketplaceUserProfile: {
      handler() {
        this.formatServices()
      },
      deep: true,
    },
    portUidDictionary: {
      handler() {
        this.formatServices()
      },
    },
  },

  created() {
    this.formatServices()
  },

  methods: {
    ...mapActions('Marketplace', ['updateMarketplaceProfile']),
    /**
     * Format the services array by adding additional information used in the UI.
     */
    formatServices() {
      const profileServices = this.marketplaceUserProfile.services
      if (!profileServices || !profileServices.length) return

      const updatedServicesArray = []

      profileServices.forEach(service => {
        const serviceInfo = this.findPort(service.productUid)
        const existingService = this.servicesArray.find(({ productUid }) => productUid === service.productUid)

        // Create an extended service object with a state to track if it's expanded,
        // along with additional information for filtering, sorting and display purposes.
        const newServiceObject = {
          ...service,
          expanded: existingService?.expanded || false,
          createDate: serviceInfo?.createDate,
          locationName: serviceInfo?.locationDetail?.metro,
          serviceTitle: serviceInfo?.title, // Existing name (different from marketplace name)
          productType: serviceInfo?.productType,
          locationInfo: `${serviceInfo?.locationDetail?.name}, ${serviceInfo?.locationDetail?.city}, ${serviceInfo?.locationDetail?.country}`,
          diversityZone: serviceInfo?.diversityZone,
        }

        // If the service already exists in the array, replace it with the updated service object.
        if (existingService) {
          const index = this.servicesArray.indexOf(existingService)
          updatedServicesArray[index] = newServiceObject
        } else {
          updatedServicesArray.push(newServiceObject)
        }
      })

      this.servicesArray = updatedServicesArray
    },
    /**
     * Format the service types into a comma-separated string.
     * @param {object[]} serviceTypes Array of service types containing an ID and description.
     */
    serviceTypes(serviceTypes) {
      return serviceTypes.map(service => service.description).join(', ')
    },
    /**
     * Format the date into a human-readable format.
     * @param {number} date Date to format.
     */
    formatDate(date) {
      if (!date) return this.$t('general.unknown')
      return DateTime.fromMillis(date).toFormat('LLL dd, yyyy, hh:mm a')
    },
    /**
     * Get the correct tag variant based on the service and profile visibility.
     * @param {boolean} visible Whether that particular service is visible on the marketplace.
     */
    tagVariant(visible) {
      if (!this.marketplaceUserProfile.active) return 'info'
      return visible ? 'success' : 'danger'
    },
    /**
     * Toggle the expanded state of a service.
     * @param {string} productUid Product UID of the service in the servicesArray to expand.
     */
    expandService(productUid) {
      const selectedService = this.servicesArray.find(service => service.productUid === productUid)
      selectedService.expanded = !selectedService.expanded
    },
    /**
     * Toggle expanded state of all services.
     * If all services are expanded, collapse all services.
     */
    expandCollapseAll() {
      const allExpanded = this.servicesArray.every(service => service.expanded)
      this.servicesArray.forEach(service => {
        service.expanded = !allExpanded
      })
    },
    /**
     * Open the edit service modal with the selected service.
     * @param {string} productUid Product UID of the service to edit.
     */
    openEditModal(productUid) {
      this.editService = this.servicesArray.find(service => service.productUid === productUid)
      this.showEditModal = true
    },
    /**
     * Update the marketplace profile with the updated service data.
     * @param {object} updatedService Updated service object to overwrite.
     */
    saveServices(updatedService) {
      const servicesPayload = this.servicesArray.map(service => {
        if (service.productUid === updatedService.productUid) {
          return updatedService
        }
        return service
      })

      this.updateMarketplaceProfile({
        ...this.marketplaceUserProfile,
        services: servicesPayload,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.empty-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;

  .fas {
    font-size: 4rem;
    color: var(--color-text-regular);
  }

  p {
    margin-bottom: 0;
  }
}

h4 {
  line-height: normal;
}

// Adding a min-width to prevent width jumping when text changes
.expand-collapse-button {
  min-width: 170px;
}

.min-w-140px {
  min-width: 140px;
}

::v-deep {
  .el-card.collapsed {
    border-bottom: none;
  }

  .el-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 0.5rem;
  }

  .el-card__body {
    padding: 0;
  }
}

.sort-select {
  width: 230px;
}

.service-title {
  max-width: 500px;
}

.card-footer {
  background-color: var(--color-info-light-9);
  padding-inline: 2rem;
  padding-block: 1rem;
}

.expand-button {
  padding: 0;
  width: 40px;
  height: 40px;

  .far {
    transition: transform 0.3s;

    &.rotated {
      transform: rotate(180deg);
    }

    &:focus {
      outline: none;
    }
  }
}

.visibility-tag {
  border-radius: 20rem;
}

.services-filter-sort {
  display: inline-flex;
  gap: 1rem;
}

// Transition styles for the service cards
.services-move,
.services-enter-active,
.services-leave-active {
  transition: all 0.3s ease-in-out;
}
.services-enter-from,
.services-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.services-leave-active {
  position: absolute;
}
</style>
