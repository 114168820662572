<template>
  <section>
    <div class="profile-header">
      <h4 class="color-regular">
        {{ $t('marketplace.thing-details', { thing: $t('general.about') }) }}
      </h4>
      <el-button v-if="canEdit"
        @click="showEditModal = true">
        <i class="far fa-cog"
          aria-hidden="true" />
        {{ $t('general.edit') }}
      </el-button>
    </div>

    <!-- Company Name -->
    <simple-heading-text-block :label="$t('marketplace.display-name')"
      :value="marketplaceUserProfile.companyName" />

    <div class="d-flex gap-1">
      <!-- Company Logo -->
      <simple-heading-text-block :label="$t('marketplace.company-logo')">
        <img :src="logoDisplay"
          :alt="$t('images.marketplace-profile-logo')"
          class="profile-logo"
          @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
      </simple-heading-text-block>

      <!-- Company Bio -->
      <simple-heading-text-block :label="$t('marketplace.company-bio')"
        :value="marketplaceUserProfile.companyBio" />
    </div>

    <!-- Provider Types -->
    <simple-heading-text-block :label="$tc('marketplace.pluralize-provider-type', 2)">
      <div v-loading="loadingProviderTypes"
        class="grid grid-cols-2 provider-types">
        <span v-for="type in providerTypesList"
          :key="type.id"
          class="provider-type"
          :data-testid="`${slug(type.description)}${type.enabled ? '-active' : ''}`">
          <i :class="type.enabled ? 'fa-check-square': 'fa-square'"
            class="fas" />
          {{ type.description }}
        </span>
      </div>
    </simple-heading-text-block>

    <edit-about-modal :visible.sync="showEditModal" />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { setFallbackImage } from '@/utils/fallbackImage'
import { slug } from '@/helpers'

import SimpleHeadingTextBlock from '@/components/ui-components/SimpleHeadingTextBlock.vue'
import EditAboutModal from '@/components/marketplace/profile/modals/EditAboutModal.vue'

export default {
  name: 'ProfileAboutTab',

  components: {
    'simple-heading-text-block': SimpleHeadingTextBlock,
    'edit-about-modal': EditAboutModal,
  },

  data() {
    return {
      loadingProviderTypes: false,
      showEditModal: false,
    }
  },

  computed: {
    ...mapState('Marketplace', ['providerTypes']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Auth', ['hasAuth']),
    /**
     * Restrict editing to company admins only.
     */
    canEdit() {
      return this.hasAuth('company_admin')
    },
    /**
     * Returns the correct image to display as the company logo
     */
    logoDisplay() {
      // If the profile has an image and it actually contains image data
      // (usually just after uploading a new one), then use that.
      if (this.marketplaceUserProfile.image && this.marketplaceUserProfile.image !== 'true') {
        return this.marketplaceUserProfile.image
      }
      // Otherwise use the logo that is already set
      // NOTE: This will already contain the fallback image if the profile doesn't have a logo set
      return this.marketplaceUserProfile._logo
    },
    /**
     * Returns the full list of provider types with an additional property that indicates
     * if the current profile has that provider type enabled.
     */
    providerTypesList() {
      if (!this.marketplaceUserProfile.providerTypes) {
        return this.providerTypes
      }

      return this.providerTypes.map(providerType => ({
        ...providerType,
        enabled: this.marketplaceUserProfile.providerTypes.some(enabledType => enabledType.id === providerType.id),
      }))
    },
  },

  async created() {
    // If we don't have any provider types, get them from the API.
    if (!this.providerTypes.length) {
      this.loadingProviderTypes = true
      await this.getProviderTypes()
      this.loadingProviderTypes = false
    }
  },

  methods: {
    ...mapActions('Marketplace', ['getProviderTypes']),
    setFallbackImage,
    slug,
  },
}
</script>

<style lang="scss" scoped>
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

h4 {
  line-height: normal;
}

.provider-types {
  min-height: 160px;

  .provider-type {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
  .fas {
    font-size: 2.5rem;
    margin-right: 0.5rem;
    color: var(--color-info-light-5);

    &.fa-check-square {
      color: var(--color-success);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}

.profile-logo {
  width: 150px;
  height: 150px;
}
</style>
