<template>
  <section class="main-content">
    <!-- Access Denied Message -->
    <div v-if="isAccessDenied"
      class="main-content-card">
      <div class="large-message">
        <i class="fas fa-lock"
          aria-hidden="true" />
        <h4>{{ $t('general.access-denied') }}</h4>
        <p>{{ accessDeniedMessage }}</p>
      </div>
    </div>

    <!-- No Profile Exists Message -->
    <div v-else-if="!marketplaceUserProfile"
      class="main-content-card">
      <div class="large-message">
        <mu-mega-icon icon="Marketplace"
          color="var(--color-megaport-red)"
          height="100"
          class="mb-1" />
        <h4 class="m-0">
          {{ $t('marketplace.no-profile') }}
        </h4>
        <p>{{ $t('marketplace.marketplace-intro') }}</p>
        <el-button type="primary"
          @click="showCreateModal = true">
          <i class="far fa-plus"
            aria-hidden="true" />
          {{ $t('marketplace.set-up-profile') }}
        </el-button>
      </div>
    </div>

    <template v-else>
      <!-- Page Header -->
      <div class="profile-header">
        <!-- Marketplace Logo -->
        <span class="icon">
          <mu-mega-icon icon="MarketplaceLabel"
            height="50"
            class="w-auto" />
        </span>
        <!-- Heading -->
        <h2>{{ $t('marketplace.profile-settings') }}</h2>
        <div class="empty" />
      </div>

      <div class="content-padding">
        <!-- Profile Visibility Card -->
        <profile-visibility />

        <!-- Profile Settings Card -->
        <el-card shadow="never">
          <h3 class="mt-0 mb-2">
            {{ $t('marketplace.profile-settings') }}
          </h3>

          <el-tabs v-model="activeTab"
            @tab-click="updateRouteQuery">
            <el-tab-pane :label="$t('general.about')"
              name="about">
              <profile-about-tab />
            </el-tab-pane>

            <el-tab-pane :label="$t('general.contact')"
              name="contact">
              <profile-contact-tab />
            </el-tab-pane>

            <el-tab-pane :label="$t('general.services')"
              name="services">
              <profile-services-tab />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </template>

    <create-profile-modal :visible.sync="showCreateModal" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ProfileVisibility from '@/components/marketplace/profile/ProfileVisibility.vue'
import ProfileAboutTab from '@/components/marketplace/profile/ProfileAboutTab.vue'
import ProfileContactTab from '@/components/marketplace/profile/ProfileContactTab.vue'
import ProfileServicesTab from '@/components/marketplace/profile/ProfileServicesTab.vue'
import CreateProfileModal from '@/components/marketplace/profile/modals/CreateProfileModal.vue'

export default {
  name: 'ProfileSettings',

  components: {
    'profile-visibility': ProfileVisibility,
    'profile-about-tab': ProfileAboutTab,
    'profile-contact-tab': ProfileContactTab,
    'profile-services-tab': ProfileServicesTab,
    'create-profile-modal': CreateProfileModal,
  },

  inject: ['isFeatureEnabled'],

  data() {
    return {
      activeTab: 'about',
      showCreateModal: false,
    }
  },

  computed: {
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    /**
     * Deny access if the marketplace feature is disabled and the user is in a managed context.
     */
    isAccessDenied() {
      return this.isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && this.isManagedContext
    },
    /**
     * Returns the access denied message shown for users denied access.
     */
    accessDeniedMessage() {
      const pageName = this.$t(this.$route.meta.title)
      return this.$t('general.feature-flag-access-error', { pageName })
    },
  },

  created() {
    // Fetch the latest user profile data
    this.getUserMarketplaceProfile()

    // Set the active tab based on the route query
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab
    } else {
      this.updateRouteQuery()
    }
  },

  methods: {
    ...mapActions('Marketplace', ['getUserMarketplaceProfile']),
    /**
     * Update the route query to reflect the active tab.
     */
    updateRouteQuery() {
      this.$router.replace({
        query: {
          tab: this.activeTab,
        },
      }).catch(() => { /* empty function is intentional */ })
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding-block: 2rem;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.content-padding {
  padding-inline: 2rem;
}

.large-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-inline: 3rem;
  gap: 1rem;

  .fas {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: var(--color-text-regular);
  }

  .fa-lock {
    color: var(--color-warning);
  }
}

.profile-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color-base);
  padding-bottom: 1rem;
  padding-inline: 2rem;
  margin-bottom: 2rem;

  h2 {
    line-height: 1.5;
  }

  .icon, .empty {
    flex: 1;
  }
}

::v-deep {
  .el-tabs__content {
    margin-top: 2.5rem;
  }
}
</style>
