<template>
  <section>
    <div class="profile-header">
      <h4 class="color-regular">
        {{ $t('marketplace.thing-details', { thing: $t('general.contact') }) }}
      </h4>
      <el-button v-if="canEdit"
        @click="showEditModal = true">
        <i class="far fa-cog"
          aria-hidden="true" />
        {{ $t('general.edit') }}
      </el-button>
    </div>

    <div class="grid grid-cols-3">
      <!-- Contact Email -->
      <simple-heading-text-block :label="$t('marketplace.contact-email')">
        <a :href="`mailto:${marketplaceUserProfile.email}`"
          rel="noopener">
          {{ marketplaceUserProfile.email }}
        </a>
      </simple-heading-text-block>

      <!-- Website -->
      <simple-heading-text-block :label="$t('general.website')">
        <a :href="marketplaceUserProfile.www"
          target="_blank"
          rel="noopener">
          {{ marketplaceUserProfile.www }}
        </a>
        <external-link-indicator />
      </simple-heading-text-block>

      <!-- Phone -->
      <simple-heading-text-block :label="$t('general.phone')">
        <a :href="`tel:${marketplaceUserProfile.phone}`">
          {{ marketplaceUserProfile.phone }}
        </a>
      </simple-heading-text-block>
    </div>

    <!-- Social Media Links -->
    <h4 class="mt-1 mb-1-5">
      {{ $t('marketplace.social') }}
    </h4>
    <div class="grid grid-cols-2">
      <!-- Facebook -->
      <simple-heading-text-block :label="$t('marketplace.facebook')">
        <template v-if="marketplaceUserProfile.facebook">
          <a :href="marketplaceUserProfile.facebook"
            target="_blank"
            rel="noopener">
            {{ marketplaceUserProfile.facebook }}
          </a>
          <external-link-indicator />
        </template>
        <p v-else>
          -
        </p>
      </simple-heading-text-block>

      <!-- Twitter/X -->
      <simple-heading-text-block :label="$t('marketplace.x')">
        <template v-if="marketplaceUserProfile.twitter">
          <a :href="marketplaceUserProfile.twitter"
            target="_blank"
            rel="noopener">
            {{ marketplaceUserProfile.twitter }}
          </a>
          <external-link-indicator />
        </template>
        <p v-else>
          -
        </p>
      </simple-heading-text-block>

      <!-- Linkedin -->
      <simple-heading-text-block :label="$t('marketplace.linkedin')"
        class="mb-1-4">
        <template v-if="marketplaceUserProfile.linkedin">
          <a :href="marketplaceUserProfile.linkedin"
            target="_blank"
            rel="noopener">
            {{ marketplaceUserProfile.linkedin }}
          </a>
          <external-link-indicator />
        </template>
        <p v-else>
          -
        </p>
      </simple-heading-text-block>
    </div>

    <!-- Headquarter Fields -->
    <h4 class="mt-1 mb-1-5">
      {{ $t('marketplace.headquarters') }}
    </h4>
    <div class="grid grid-cols-2">
      <!-- Address 1 -->
      <simple-heading-text-block :label="$t('marketplace.address-1')"
        :value="marketplaceUserProfile.street1" />
      <!-- Address 2 -->
      <simple-heading-text-block :label="$t('marketplace.address-2')"
        :value="marketplaceUserProfile.street2" />
      <!-- City/Suburb -->
      <simple-heading-text-block :label="$t('marketplace.city')"
        :value="marketplaceUserProfile.city" />
      <!-- State -->
      <simple-heading-text-block :label="$t('marketplace.state')"
        :value="marketplaceUserProfile.state" />
      <!-- Postcode -->
      <simple-heading-text-block :label="$t('marketplace.postcode')"
        :value="marketplaceUserProfile.postcode" />
      <!-- Country -->
      <simple-heading-text-block :label="$t('marketplace.country')"
        :value="marketplaceUserProfile.country" />
    </div>

    <edit-contact-modal :visible.sync="showEditModal" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import SimpleHeadingTextBlock from '@/components/ui-components/SimpleHeadingTextBlock.vue'
import ExternalLinkIndicator from '@/components/ui-components/ExternalLinkIndicator.vue'
import EditContactModal from '@/components/marketplace/profile/modals/EditContactModal.vue'

export default {
  name: 'ProfileContactTab',

  components: {
    'simple-heading-text-block': SimpleHeadingTextBlock,
    'external-link-indicator': ExternalLinkIndicator,
    'edit-contact-modal': EditContactModal,
  },

  data() {
    return {
      showEditModal: false,
    }
  },

  computed: {
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Auth', ['hasAuth']),
    /**
     * Restrict editing to company admins only.
     */
    canEdit() {
      return this.hasAuth('company_admin')
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

h4 {
  line-height: normal;
}

.grid {
  a, p {
    word-break: break-word;
  }
}
</style>
