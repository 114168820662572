<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('marketplace.edit-contact')"
    @open="initForm">
    <el-form ref="contactForm"
      :model="contactForm"
      :rules="contactFormRules"
      label-position="top">
      <!-- Contact Email -->
      <el-form-item prop="email"
        :label="$t('general.email')">
        <el-input id="email"
          v-model="contactForm.email"
          name="email"
          data-demo="test@email.com" />
      </el-form-item>

      <!-- Website -->
      <el-form-item prop="www"
        :label="$t('general.website')">
        <el-input id="www"
          v-model="contactForm.www"
          name="www"
          data-demo="https://www.megaport.com/" />
      </el-form-item>

      <!-- Phone -->
      <el-form-item prop="phone"
        :label="$t('general.phone')">
        <el-input id="phone"
          v-model="contactForm.phone"
          name="phone"
          data-demo="+61 7 12341234" />
      </el-form-item>

      <h4 class="d-inline-block mt-1">
        {{ $t('marketplace.social') }}
      </h4>
      <div class="grid grid-cols-2">
        <!-- Facebook -->
        <el-form-item prop="facebook"
          :label="$t('marketplace.facebook')">
          <el-input id="facebook"
            v-model="contactForm.facebook"
            name="facebook"
            data-demo="https://www.facebook.com/megaport/" />
        </el-form-item>

        <!-- Twitter/X -->
        <el-form-item prop="twitter"
          :label="$t('marketplace.x')">
          <el-input id="twitter"
            v-model="contactForm.twitter"
            name="twitter"
            data-demo="https://x.com/megaportnetwork/" />
        </el-form-item>

        <!-- Linkedin -->
        <el-form-item prop="linkedin"
          :label="$t('marketplace.linkedin')">
          <el-input id="linkedin"
            v-model="contactForm.linkedin"
            name="linkedin"
            data-demo="https://www.linkedin.com/company/megaport/" />
        </el-form-item>
      </div>

      <h4 class="mt-1">
        {{ $t('marketplace.headquarters') }}
      </h4>
      <div class="grid grid-cols-2">
        <!-- Street 1 -->
        <el-form-item prop="street1"
          :label="$t('marketplace.address-1')">
          <el-input id="street1"
            v-model="contactForm.street1"
            name="street1"
            data-demo="308 Negra Arroyo Lane" />
        </el-form-item>

        <!-- Street 2 -->
        <el-form-item prop="street2"
          :label="$t('marketplace.address-2')">
          <el-input id="street2"
            v-model="contactForm.street2"
            name="street2"
            data-demo="Cnr McGill St" />
        </el-form-item>

        <!-- City -->
        <el-form-item prop="city"
          :label="$t('marketplace.city')">
          <el-input id="city"
            v-model="contactForm.city"
            name="city"
            data-demo="Albuquerque" />
        </el-form-item>

        <!-- State -->
        <el-form-item prop="state"
          :label="$t('marketplace.state')">
          <el-input id="state"
            v-model="contactForm.state"
            name="state"
            data-demo="New Mexico" />
        </el-form-item>

        <!-- Post Code -->
        <el-form-item prop="postcode"
          :label="$t('marketplace.postcode')">
          <el-input id="postcode"
            v-model="contactForm.postcode"
            name="postcode"
            data-demo="87104" />
        </el-form-item>

        <!-- Country -->
        <el-form-item prop="country"
          :label="$t('marketplace.country')">
          <el-input id="country"
            v-model="contactForm.country"
            name="country"
            data-demo="United States" />
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <el-button type="primary"
        @click="submitForm">
        {{ $t('general.save') }}
      </el-button>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { PHONE_NUMBER_REGEX, FACEBOOK_REGEX, TWITTER_REGEX, LINKEDIN_REGEX } from '@/validators'

export default {
  name: 'EditContactModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      contactForm: {
        email: null,
        www: null,
        phone: null,
        // Social media links
        facebook: null,
        twitter: null,
        linkedin: null,
        // Headquarters
        street1: null,
        street2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
      },
      contactFormRules: {
        email: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('general.email') }),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('validations.email-invalid'),
            trigger: 'blur',
          },
        ],
        www: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('general.website') }),
            trigger: 'blur',
          },
          {
            type: 'url',
            message: this.$t('validations.url-format', { site: this.$t('general.website') }),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('general.phone') }),
            trigger: 'blur',
          },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('billing-markets.phone-format'), trigger: ['blur'] },
        ],
        facebook: {
          pattern: FACEBOOK_REGEX,
          message: this.$t('validations.url-format', { site: this.$t('marketplace.facebook') }),
          trigger: 'blur',
        },
        twitter: {
          pattern: TWITTER_REGEX,
          message: this.$t('validations.url-format', { site: this.$t('marketplace.x') }),
          trigger: 'blur',
        },
        linkedin: {
          pattern: LINKEDIN_REGEX,
          message: this.$t('validations.url-format', { site: this.$t('marketplace.linkedin') }),
          trigger: 'blur',
        },
      },
    }
  },

  computed: {
    ...mapState('Marketplace', ['providerTypes']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
  },

  methods: {
    ...mapActions('Marketplace', ['updateMarketplaceProfile']),
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Initialise the form by resetting validation and setting values to the current profile values
     */
    initForm() {
      this.$nextTick(() => {
        this.$refs.contactForm.clearValidate()
      })

      this.contactForm = {
        email: this.marketplaceUserProfile.email,
        www: this.marketplaceUserProfile.www,
        phone: this.marketplaceUserProfile.phone,
        facebook: this.marketplaceUserProfile.facebook,
        twitter: this.marketplaceUserProfile.twitter,
        linkedin: this.marketplaceUserProfile.linkedin,
        street1: this.marketplaceUserProfile.street1,
        street2: this.marketplaceUserProfile.street2,
        city: this.marketplaceUserProfile.city,
        state: this.marketplaceUserProfile.state,
        postcode: this.marketplaceUserProfile.postcode,
        country: this.marketplaceUserProfile.country,
      }
    },
    /**
     * Updates the supplied marketplace profile fields via the API
     */
    submitForm() {
      this.$refs.contactForm.validate(async valid => {
        // Show validation error message if the form is invalid
        if (!valid) {
          this.$notify({
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          })
          return
        }

        await this.updateMarketplaceProfile({
          ...this.marketplaceUserProfile,
          ...this.contactForm,
        })

        this.setVisible(false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 800px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
