<template>
  <el-card shadow="never"
    class="mb-2">
    <h3 class="mt-0 mb-2">
      {{ $t('marketplace.profile-visibility') }}
    </h3>

    <div class="d-flex justify-content-space-between flex-align-center">
      <!-- Profile Visible Message -->
      <div class="d-flex flex-align-center">
        <mu-mega-icon icon="Marketplace"
          :fill-color="active ? 'var(--color-success)' : 'var(--color-warning)'"
          height="40"
          class="flex-1 mr-1" />
        <p>
          <strong>{{ $t(`marketplace.profile-${active ? 'visible' : 'hidden'}`) }}</strong>
        </p>
      </div>

      <div>
        <!-- Preview Link -->
        <router-link v-if="active"
          :to="previewLink"
          class="mr-0-5">
          <el-button>
            <i class="far fa-eye"
              aria-hidden="true" />
            {{ $t('marketplace.preview') }}
          </el-button>
        </router-link>

        <!-- Edit Visibility Button -->
        <el-button @click="visibilityModal = true">
          <i class="far fa-cog"
            aria-hidden="true" />
          {{ $t('general.edit') }}
        </el-button>
      </div>
    </div>

    <edit-visibility-modal :visible.sync="visibilityModal" />
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { slug } from '@/helpers'

import EditVisibilityModal from '@/components/marketplace/profile/modals/EditVisibilityModal.vue'

export default {
  name: 'ProfileVisibility',

  components: {
    'edit-visibility-modal': EditVisibilityModal,
  },

  data() {
    return {
      visibilityModal: false,
    }
  },

  computed: {
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    /** Returns the profile's current active status */
    active() {
      return this.marketplaceUserProfile.active
    },
    /**
     * Get the preview link for the user's active marketplace profile.
     */
    previewLink() {
      return `/marketplace/${slug(this.marketplaceUserProfile.companyName)}`
    },
  },
}
</script>
