<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('general.warning-label')"
    append-to-body>
    <p v-if="providerTypes.length">
      {{ $t('marketplace.services-warning', { providerTypes: providerTypesString }) }}
    </p>

    <p v-else>
      {{ $t('marketplace.duplicated-names') }}
    </p>

    <ol class="pl-1-5">
      <li v-for="(serviceTitle, index) in services"
        :key="`${serviceTitle}-${index}`"
        class="mb-0-5">
        {{ serviceTitle }}
      </li>
    </ol>

    <p v-if="!providerTypes.length">
      {{ $t('marketplace.duplicate-name-warning') }}
    </p>

    <template #footer>
      <div class="d-flex flex-justify-center">
        <el-button class="full-width"
          @click="setVisible(false)">
          {{ $t('general.cancel') }}
        </el-button>
        <el-button type="warning"
          class="full-width"
          @click="confirm">
          {{ $t('general.confirm') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
/**
 * This is a really basic modal that displays a warning message and a list of services tailored
 * specifically for saving in the about and services tabs. It can be expanded to be more generic
 * in future if needed.
 */
export default {
  name: 'ConfirmChangesModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Array of provider type descriptions (string[])
    providerTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    // Array of service titles (string[])
    services: {
      type: Array,
      required: true,
    },
  },

  emits: ['update:visible', 'confirm'],

  computed: {
    /**
     * Returns a formatted string of provider types
     * @returns {string} Comma-separated list of provider types
     */
    providerTypesString() {
      return this.providerTypes.join(', ')
    },
  },

  methods: {
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Closes modal and emits back to parent
     */
    confirm() {
      this.$emit('confirm')
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    width: 550px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
