<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('marketplace.set-up-profile')">
    <el-form ref="profileForm"
      :model="profileForm"
      :rules="profileFormRules"
      label-position="top">
      <!-- Company Name -->
      <el-form-item prop="companyName"
        :label="$t('marketplace.display-name')">
        <el-input id="companyName"
          v-model="profileForm.companyName"
          name="companyName"
          data-demo="Megaport" />
      </el-form-item>

      <!-- Company Bio -->
      <el-form-item prop="companyBio"
        :label="$t('marketplace.company-bio')">
        <el-input id="companyBio"
          v-model="profileForm.companyBio"
          type="textarea"
          rows="8"
          name="companyBio"
          data-demo="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
      </el-form-item>
      <p :class="bioWordCountClass"
        class="text-align-right full-width mb-1 mx-0 mt-negative-1 font-weight-400">
        {{ bioWordCount }}
      </p>

      <!-- Contact Email -->
      <el-form-item prop="email"
        :label="$t('general.email')">
        <el-input id="email"
          v-model="profileForm.email"
          name="email"
          data-demo="test@email.com" />
      </el-form-item>

      <!-- Website -->
      <el-form-item prop="www"
        :label="$t('general.website')">
        <el-input id="www"
          v-model="profileForm.www"
          name="www"
          data-demo="https://www.megaport.com/" />
      </el-form-item>

      <!-- Phone -->
      <el-form-item prop="phone"
        :label="$t('general.phone')">
        <el-input id="phone"
          v-model="profileForm.phone"
          name="phone"
          data-demo="+61 7 12341234" />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary"
        @click="submitForm">
        {{ $t('marketplace.create-profile') }}
      </el-button>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { validateCompanyBio, PHONE_NUMBER_REGEX } from '@/validators'
import { BIO_MAX_WORD_COUNT } from '@/Globals'

export default {
  name: 'CreateProfileModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      BIO_MAX_WORD_COUNT,
      profileForm: {
        companyName: null,
        companyBio: null,
        email: null,
        www: null,
        phone: null,
      },
      profileFormRules: {
        companyName: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('marketplace.display-name') }),
            trigger: 'blur',
          },
          {
            pattern: '^[A-z0-9].{2,64}$', // Must start with a letter or number and be between 2 and 64 characters
            message: this.$t('validations.valid-company'),
            trigger: 'blur',
          },
        ],
        companyBio: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('marketplace.company-bio') }),
            trigger: 'blur',
          },
          { validator: validateCompanyBio, trigger: ['change', 'blur'] },
        ],
        email: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('general.email') }),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('validations.email-invalid'),
            trigger: 'blur',
          },
        ],
        www: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('general.website') }),
            trigger: 'blur',
          },
          {
            type: 'url',
            message: this.$t('validations.url-format', { site: this.$t('general.website') }),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('general.phone') }),
            trigger: 'blur',
          },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('billing-markets.phone-format'), trigger: ['blur'] },
        ],
      },
    }
  },

  computed: {
    ...mapGetters('Company', ['companyUid']),
    /**
     * Calculates the current word count of the company bio.
     * @returns {string} The word count message formatted with the current count and the maximum allowed words.
     */
    bioWordCount() {
      let count = 0
      if (this.profileForm.companyBio && this.profileForm.companyBio.trim() !== '') {
        count = this.profileForm.companyBio.trim().split(/[\s]+/).length
      }
      return this.$t('general.word-counter', { count, maxWords: BIO_MAX_WORD_COUNT })
    },
    /**
     * Returns the class to apply to the bio word count message based on the current word count.
     */
    bioWordCountClass() {
      return !this.profileForm.companyBio || this.profileForm.companyBio.trim().split(/[\s]+/).length <= BIO_MAX_WORD_COUNT
        ? 'color-success'
        : 'color-danger'
    },
  },

  methods: {
    ...mapActions('Marketplace', ['updateMarketplaceProfile']),
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Updates the marketplace profile visibility via the API
     */
    submitForm() {
      this.$refs.profileForm.validate(async valid => {
        // Show validation error message if the form is invalid
        if (!valid) {
          this.$notify({
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          })
          return
        }

        await this.updateMarketplaceProfile({
          ...this.profileForm,
          active: false,
          companyUid: this.companyUid,
          services: [],
        })

        this.setVisible(false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 800px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
