<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('marketplace.edit-about')"
    @open="initForm">
    <el-form ref="aboutForm"
      :model="aboutForm"
      :rules="aboutFormRules"
      label-position="top">
      <!-- Company Name -->
      <el-form-item prop="companyName"
        :label="$t('marketplace.display-name')">
        <el-input id="companyName"
          v-model="aboutForm.companyName"
          name="companyName"
          data-demo="Megaport"
          data-testid="companyName" />
      </el-form-item>

      <!-- Company Logo -->
      <div class="mb-1-4">
        <p class="m-0">
          {{ $t('marketplace.company-logo') }}
          <el-tooltip placement="top"
            :open-delay="500"
            :content="$t('marketplace.logo-notice')">
            <i class="fas fa-info-circle"
              aria-hidden="true" />
          </el-tooltip>
        </p>
        <!-- No Image Box -->
        <div v-if="marketplaceUserProfile.image !== 'true' && !aboutForm.image"
          class="no-image"
          @click="showUploadImageModal = true">
          <p>{{ $t('dashboard.marketplace-image') }}</p>

          <el-button @click="showUploadImageModal = true">
            {{ $t('marketplace.upload-logo') }}
          </el-button>
        </div>

        <!-- Current Profile Logo -->
        <template v-else>
          <img :src="logoDisplay"
            :alt="$t('images.marketplace-profile-logo')"
            class="profile-logo"
            @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">

          <el-button class="change-logo-button"
            size="small"
            @click="showUploadImageModal = true">
            {{ $t('marketplace.change-logo') }}
          </el-button>
        </template>
      </div>

      <!-- Company Bio -->
      <el-form-item prop="companyBio"
        :label="$t('marketplace.company-bio')">
        <el-input id="companyBio"
          v-model="aboutForm.companyBio"
          type="textarea"
          rows="8"
          name="companyBio"
          data-demo="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
      </el-form-item>
      <p :class="bioWordCountClass"
        class="text-align-right full-width mb-1 mx-0 mt-negative-1 font-weight-400">
        {{ bioWordCount }}
      </p>

      <!-- Provider Types -->
      <el-form-item v-if="providerTypes.length"
        :label="$tc('marketplace.pluralize-provider-type', 2)"
        prop="providerSelected">
        <el-checkbox-group id="providerSelected"
          v-model="aboutForm.providerTypeIds"
          class="grid grid-cols-2">
          <el-checkbox v-for="type in providerTypes"
            :key="type.id"
            :name="`providerType-${type.id}`"
            :label="type.id"
            class="line-height-0 d-flex mb-1 text-wrap">
            {{ type.description }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary"
        @click="submitForm">
        {{ $t('general.save') }}
      </el-button>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
    </template>

    <!-- Image Upload Modal -->
    <image-upload v-model="aboutForm.image"
      :visible.sync="showUploadImageModal" />

    <!-- Confirmation dialog when removing provider types -->
    <confirm-changes-modal :visible.sync="showConfirmModal"
      :provider-types="affectedProviderTypeDescriptions"
      :services="affectedServiceNames"
      @confirm="saveProfile"
      @update:visible="setVisible(false)" />
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { setFallbackImage } from '@/utils/fallbackImage'
import { validateCompanyBio } from '@/validators'
import { BIO_MAX_WORD_COUNT } from '@/Globals'

import ImageUpload from '@/components/marketplace/ImageUpload.vue'
import ConfirmChangesModal from '@/components/marketplace/profile/modals/ConfirmChangesModal.vue'

export default {
  name: 'EditAboutModal',

  components: {
    'image-upload': ImageUpload,
    'confirm-changes-modal': ConfirmChangesModal,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      BIO_MAX_WORD_COUNT,
      aboutForm: {
        companyName: null,
        companyBio: null,
        image: null,
        providerTypeIds: [],
      },
      aboutFormRules: {
        companyName: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('marketplace.display-name') }),
            trigger: 'blur',
          },
          {
            pattern: '^[A-z0-9].{2,64}$', // Must start with a letter or number and be between 2 and 64 characters
            message: this.$t('validations.valid-marketplace-name'),
            trigger: 'blur',
          },
        ],
        companyBio: {
          required: true,
          validator: validateCompanyBio,
          trigger: ['change', 'blur'],
        },
      },
      // Modal visibility states
      showUploadImageModal: false,
      showConfirmModal: false,
      // Affected data to be passed to the confirmation modal
      affectedProviderTypeDescriptions: [],
      affectedServiceNames: [],
    }
  },

  computed: {
    ...mapState('Marketplace', ['providerTypes']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    /**
     * Calculates the current word count of the company bio.
     * @returns {string} The word count message formatted with the current count and the maximum allowed words.
     */
    bioWordCount() {
      let count = 0
      if (this.aboutForm.companyBio && this.aboutForm.companyBio.trim() !== '') {
        count = this.aboutForm.companyBio.trim().split(/[\s]+/).length
      }
      return this.$t('general.word-counter', { count, maxWords: BIO_MAX_WORD_COUNT })
    },
    /**
     * Returns the class to apply to the bio word count message based on the current word count.
     */
    bioWordCountClass() {
      return !this.aboutForm.companyBio || this.aboutForm.companyBio.trim().split(/[\s]+/).length <= BIO_MAX_WORD_COUNT
        ? 'color-success'
        : 'color-danger'
    },
    /**
     * Returns the correct image to display as the company logo, prioritising the newly uploaded image
     */
    logoDisplay() {
      if (this.aboutForm.image) return this.aboutForm.image
      return this.marketplaceUserProfile._logo
    },
    /**
     * Returns the service types that will be removed depending on the provider types that are set to be removed.
     */
    removedServiceTypes() {
      return this.marketplaceUserProfile.providerTypes
        .filter(providerType => !this.aboutForm.providerTypeIds.includes(providerType.id))
        .flatMap(type => this.providerTypes.find(providerType => type.id === providerType.id))
        .flatMap(type => type.serviceTypes)
    },
  },

  methods: {
    setFallbackImage,
    ...mapActions('Marketplace', ['updateMarketplaceProfile']),
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Initialise the form by resetting validation and setting values to the current profile values
     */
    initForm() {
      this.$nextTick(() => {
        this.$refs.aboutForm.clearValidate()
      })

      this.aboutForm = {
        companyName: this.marketplaceUserProfile.companyName,
        companyBio: this.marketplaceUserProfile.companyBio,
        image: null,
        providerTypeIds: this.marketplaceUserProfile.providerTypes.map(providerType => providerType.id),
      }
    },
    /**
     * Submits the form and validates the data submitted
     */
    submitForm() {
      this.$refs.aboutForm.validate(valid => {
        // Show validation error message if the form is invalid
        if (!valid) {
          this.$notify({
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          })
          return
        }

        // If we've removed any provider types, we need to check which services
        // are affected and show a confirmation modal to the user.
        if (this.removedServiceTypes.length) {
          this.affectedProviderTypeDescriptions = this.marketplaceUserProfile.providerTypes
            .filter(providerType => !this.aboutForm.providerTypeIds.includes(providerType.id))
            .map(pt => pt.description)

          // Create a new variable that lists any services that have any of these service types
          this.affectedServiceNames = this.marketplaceUserProfile.services.filter(service => {
            return service.serviceTypes
              .some(serviceType => this.removedServiceTypes.map(rst => rst.id).includes(serviceType.id))
          }).map(service => service.title)

          // If any services are affected, show the confirmation modal otherwise submit the update to the API.
          if (this.affectedServiceNames.length) {
            this.showConfirmModal = true
          } else {
            this.saveProfile()
          }
          return
        }

        // If no provider types have been removed, we can just submit the profile update.
        this.saveProfile()
      })
    },
    /**
     * Updates the supplied marketplace profile fields via the API
     */
    async saveProfile() {
      // A copy of the services array that we can manipulate
      const servicesPayload = [...this.marketplaceUserProfile.services]

      // If we've removed any provider types, we need to go through each service and remove
      // any child service types under that provider type that will no longer be available for use.
      if (this.removedServiceTypes.length) {
        servicesPayload.forEach(service => {
          service.serviceTypes = service.serviceTypes.filter(serviceType => {
            return !this.removedServiceTypes.map(rst => rst.id).includes(serviceType.id)
          })
        })
      }

      // Get the full provider type info based on the selected IDs
      const providerTypes = this.aboutForm.providerTypeIds.map(id => {
        return this.providerTypes.find(providerType => id === providerType.id)
      })

      await this.updateMarketplaceProfile({
        ...this.marketplaceUserProfile,
        companyName: this.aboutForm.companyName,
        companyBio: this.aboutForm.companyBio,
        image: this.aboutForm.image,
        providerTypes,
        services: servicesPayload,
      })

      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 800px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-select {
    width: 100%;
  }
}

.profile-logo {
  width: 150px;
  height: 150px;
}

.no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  padding: 2rem;
  border-radius: var(--border-radius-base);
  border: 1px solid var(--color-info);

  &:hover {
    border-color: var(--color-primary);
  }
}

.change-logo-button {
  display: block;
  width: 150px;
}
</style>
