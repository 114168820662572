<template>
  <div class="parent">
    <h5>{{ label }}</h5>
    <slot>
      <p>{{ value || '-' }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'SimpleReadOnlyData',

  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.parent:not(:last-child) {
  margin-bottom: 1.5rem;
}

h5 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
}

p {
  margin: 0;
  white-space: pre-line;
}
</style>
