<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('marketplace.edit-service')"
    @open="initForm">
    <el-form ref="serviceForm"
      :model="serviceForm"
      :rules="serviceFormRules"
      label-position="top">
      <!-- Service Info -->
      <div class="service-info">
        <mu-mega-icon :icon="service.productType"
          height="40"
          class="w-auto" />
        
        <div>
          <!-- Title -->
          <h5>{{ service.serviceTitle }}</h5>
          <!-- Location -->
          <p>{{ service.locationInfo }}</p>
          <!-- Diversity Zone -->
          <p v-if="service.diversityZone"
            :title="$t(`services.${service.diversityZone}-zone`)"
            class="diversity-container">
            {{ $t('services.diversity-zone') }}
            <i :class="`diversity-color-${service.diversityZone}`"
              class="far fa-dot-circle diversity-zone-icon"
              aria-hidden="true" />
            <span class="sr-only">{{ $t(`general.${service.diversityZone}`) }}</span>
          </p>
        </div>
      </div>

      <!-- Service Visibility -->
      <el-form-item prop="marketplaceVisible"
        :label="$t('marketplace.profile-visibility')">
        <el-select id="marketplaceVisible"
          v-model="serviceForm.marketplaceVisible">
          <el-option v-for="option in visibilityOptions"
            :key="option.label"
            :label="option.label"
            :value="option.value" />
        </el-select>
      </el-form-item>
      <!-- Warning message if setting a private service to public -->
      <p v-if="!service.marketplaceVisible && serviceForm.marketplaceVisible">
        {{ publicWarningMessage }}
      </p>

      <!-- Service Title -->
      <el-form-item prop="title"
        :label="$t('marketplace.display-name')">
        <el-input id="title"
          v-model="serviceForm.title"
          name="title"
          data-demo="Jedi" />
      </el-form-item>

      <!-- Service Description -->
      <el-form-item prop="description"
        :label="$t('general.description')">
        <el-input id="description"
          v-model="serviceForm.description"
          type="textarea"
          rows="6"
          name="description"
          data-demo="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
      </el-form-item>
      <p :class="descriptionWordCountClass"
        class="text-align-right full-width mb-1 mx-0 mt-negative-1 font-weight-400">
        {{ descriptionWordCount }}
      </p>

      <!-- Contact Email -->
      <el-form-item prop="contactEmail"
        :label="$t('marketplace.contact-email')">
        <el-input id="contactEmail"
          v-model="serviceForm.contactEmail"
          name="contactEmail"
          data-demo="test@email.com" />
      </el-form-item>

      <!-- Service Types -->
      <el-form-item v-if="availableServiceTypes.length"
        :label="$t('marketplace.service-types')"
        prop="serviceTypeIds">
        <el-checkbox-group id="serviceTypeIds"
          v-model="serviceForm.serviceTypeIds"
          class="grid grid-cols-2">
          <el-checkbox v-for="type in availableServiceTypes"
            :key="type.id"
            :name="`serviceType-${type.id}`"
            :label="type.id"
            class="line-height-0 d-flex mb-1 text-wrap">
            {{ type.description }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary"
        @click="submitForm">
        {{ $t('general.save') }}
      </el-button>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
    </template>

    <!-- Confirmation dialog when duplicated title is found -->
    <confirm-changes-modal :visible.sync="showConfirmModal"
      :services="duplicateTitles"
      @confirm="saveService"
      @update:visible="setVisible(false)" />
  </el-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { BIO_MAX_WORD_COUNT, G_PRODUCT_TYPE_MVE } from '@/Globals'
import { convertProductType } from '@/helpers'

import ConfirmChangesModal from '@/components/marketplace/profile/modals/ConfirmChangesModal.vue'

export default {
  name: 'EditServiceModal',

  components: {
    'confirm-changes-modal': ConfirmChangesModal,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    service: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:visible', 'submit'],

  data() {
    return {
      serviceForm: {
        marketplaceVisible: false,
        title: null,
        description: null,
        contactEmail: null,
        serviceTypeIds: [],
      },
      serviceFormRules: {
        marketplaceVisible: {
          required: true,
          message: this.$t('validations.required', { thing: this.$t('marketplace.profile-visibility') }),
          trigger: 'blur',
        },
        title: {
          required: true,
          message: this.$t('validations.required', { thing: this.$t('marketplace.display-name') }),
          trigger: 'blur',
        },
        description: {
          required: false,
          validator: this.validateDescription,
          trigger: ['change', 'blur'],
        },
        contactEmail: {
          type: 'email',
          message: this.$t('validations.email-invalid'),
          trigger: 'blur',
        },
      },
      visibilityOptions: [
        {
          label: this.$t('marketplace.hidden-from-marketplace'),
          value: false,
        },
        {
          label: this.$t('marketplace.visible-on-marketplace'),
          value: true,
        },
      ],
      showConfirmModal: false,
      duplicateTitles: [],
    }
  },

  computed: {
    ...mapState('Marketplace', ['providerTypes']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    /**
     * Calculates the current word count of the company bio.
     * @returns {string} The word count message formatted with the current count and the maximum allowed words.
     */
    descriptionWordCount() {
      let count = 0
      if (this.serviceForm.description && this.serviceForm.description.trim() !== '') {
        count = this.serviceForm.description.trim().split(/[\s]+/).length
      }
      return this.$t('general.word-counter', { count, maxWords: BIO_MAX_WORD_COUNT })
    },
    /**
     * Returns the class to apply to the bio word count message based on the current word count.
     */
    descriptionWordCountClass() {
      return !this.serviceForm.description || this.serviceForm.description.trim().split(/[\s]+/).length <= BIO_MAX_WORD_COUNT
        ? 'color-success'
        : 'color-danger'
    },
    /**
     * Returns warning message that shows when setting a private service to public.
     */
    publicWarningMessage() {
      const productType = convertProductType(this.service.productType)
      const limitType = productType === G_PRODUCT_TYPE_MVE ? this.$t('marketplace.size') : this.$t('marketplace.speed')
      return this.$t('marketplace.public-service-warning', { productType, limitType })
    },
    /**
     * Get the available service types based on the currently selected provider types.
     */
    availableServiceTypes() {
      return this.marketplaceUserProfile.providerTypes.flatMap(selectedProviderType => {
        const foundProviderType = this.providerTypes.find(providerType => providerType.id === selectedProviderType.id)
        return foundProviderType?.serviceTypes ?? []
      })
    },
  },

  methods: {
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },

    /**
     * Validates the description field ensuring it is below the max word count.
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateDescription(_rule, value, callback) {
      if (value.length > 2000) { // Max length is 2000 characters
        callback(window.mpApp.$tc('validations.max-length', 2000, { max: 2000 }))
      } else if (value.trim().split(/[\s]+/).length > BIO_MAX_WORD_COUNT) { // Max word count is 200
        callback(window.mpApp.$t('validations.description-too-long'))
      } else {
        callback()
      }
    },
    /**
     * Initialise the form by resetting validation and setting values to the current profile values
     */
    initForm() {
      this.$nextTick(() => {
        this.$refs.serviceForm.clearValidate()
      })

      this.serviceForm = {
        marketplaceVisible: this.service.marketplaceVisible,
        title: this.service.title,
        description: this.service.description,
        contactEmail: this.service.contactEmail,
        serviceTypeIds: this.service.serviceTypes.map(serviceType => serviceType.id),
        productUid: this.service.productUid,
      }
    },
    /**
     * Submits the form and validates the data submitted
     */
    submitForm() {
      this.$refs.serviceForm.validate(valid => {
        // Show validation error message if the form is invalid
        if (!valid) {
          this.$notify({
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          })
          return
        }

        // Check if the service title is unique across all public services
        // and show a confirmation dialog if it's not
        this.duplicateTitles = this.marketplaceUserProfile.services.filter(service => {
          return service.title === this.serviceForm.title
            && service.productUid !== this.serviceForm.productUid
            && service.marketplaceVisible === true
        })
          .map(service => service.title)

        // If the service we're saving is public and there is a duplicated title, show a confirmation dialog
        if (this.serviceForm.marketplaceVisible && this.duplicateTitles.length) {
          this.showConfirmModal = true
        } else {
          this.saveService()
        }
      })
    },
    /**
     * Emits the updated service to be updated via the API
     */
    saveService() {
      // Get the full service type info based on the selected IDs
      const serviceTypes = this.serviceForm.serviceTypeIds.map(id => {
        return this.availableServiceTypes.find(serviceType => id === serviceType.id)
      })

      const servicePayload = {
        ...this.serviceForm,
        marketplaceVisible: this.serviceForm.marketplaceVisible,
        title: this.serviceForm.title,
        description: this.serviceForm.description,
        contactEmail: this.serviceForm.contactEmail,
        serviceTypes,
      }

      this.$emit('submit', servicePayload)
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 600px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-select {
    width: 100%;
  }
}

.service-info {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  p {
    margin: 0;
  }
}

.diversity-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.diversity-zone-icon {
  margin-right: 0.25rem;
  font-size: 1.2rem;
}
</style>
